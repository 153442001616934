<template>
  <div class="tutorial-layout">
    <HoTheTutorialHeader class="the-header" />
    <ToastList />
    <div class="container">
      <div class="page">
        <div class="inner">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useGtm, createGtm } from '@gtm-support/vue-gtm'

import HoTheTutorialHeader from '@/components/ho/HoTheTutorialHeader.vue'

// composables
import { authInjectionKey, useAuth as _useAuth } from '@/composables/useAuth'
import {
  useMyProfile as _useMyProfile,
  myProfileInjectionKey,
} from '@/composables/useMyProfile'
import {
  useProfile as _useProfile,
  profileInjectionKey,
} from '@/composables/useProfile'
import {
  useNotification as _useNotification,
  notificationInjectionKey,
} from '@/composables/useNotification'
import {
  useToast as _useToast,
  toastInjectionKey,
} from '@/composables/useToast'
import {
  useTutorial as _useTutorial,
  tutorialInjectionKey,
} from '@/composables/useTutorial'
import {
  useMypageMenu as _useMypageMenu,
  mypageMenuInjectionKey,
} from '@/composables/useMypageMenu'

import {
  useAvatar as _useAvatar,
  avatarComposablesInjectionKey,
} from '@/composables/useAvatar'

import {
  useHousing as _useHousing,
  housingInjectionKey,
} from '@/composables/useHousing'
import { useHome as _useHome, homeInjectionKey } from '@/composables/useHome'
import {
  useVrmViewer as _useVrmViewer,
  vrmViewerInjectionKey,
} from '@/composables/useVrmViewer'

const nuxtApp = useNuxtApp()
const config = useRuntimeConfig()

/** composables */
const authStore = _useAuth()
const root = useRoot()
const useToast = _useToast()
const useMyProfile = _useMyProfile()
const useProfile = _useProfile()
const useNotification = _useNotification()
const useTutorial = _useTutorial()
const useMypageMenu = _useMypageMenu()
const avatar = _useAvatar()
const housing = _useHousing()
const home = _useHome()
const vrmViewer = _useVrmViewer()
const i18n = useI18n()

provide(authInjectionKey, authStore)
provide(rootInjectionKey, root)
provide(toastInjectionKey, useToast)
provide(myProfileInjectionKey, useMyProfile)
provide(profileInjectionKey, useProfile)
provide(notificationInjectionKey, useNotification)
provide(tutorialInjectionKey, useTutorial)
provide(mypageMenuInjectionKey, useMypageMenu)
provide(avatarComposablesInjectionKey, avatar)
provide(housingInjectionKey, housing)
provide(homeInjectionKey, home)
provide(vrmViewerInjectionKey, vrmViewer)

const { startLoading, endLoading } = root
const { getMyProfile } = useMyProfile
const { me, isGottenMe, initAuth } = authStore
const { addToast } = useToast

/* gtm */
// todo: app.vue に入れるとnuxtの初期化前でエラーになる。全layout共通処理はdefault.vueから分離したい
const loadGtm = () => {
  // NOTE: ログイン状態をGTMに送信したいので、ここで初期化する
  window.dataLayer = window.dataLayer || []
  // NOTE: 既に初期化されている場合は何もしない
  if (window.dataLayer.length === 0) {
    window.dataLayer.push({
      vket_id: me.value?.vketId ?? '',
      is_logged_in: isGottenMe.value,
    })
    const gtm = useGtm()
    if (gtm?.id) return
    nuxtApp.vueApp.use(
      createGtm({
        id: config.public.NUXT_ENV_GTM_ID,
        debug: config.public.NUXT_ENV_OUTPUT_ENV !== 'production',
      })
    )
  }
}

/**
 * 認証チェック
 * 1.画面リフレッシュ時(onBeforeMountで発火)
 *   1-1.ログイン後のリダイレクト時 -> initAuthAfterLogin()
 *   1-2.通常の画面表示 -> initAuth()
 * 2.Vue-Routerでの画面遷移時(route.pathをwatchして発火) -> initAuth()
 * */
// 画面リフレッシュ時
onBeforeMount(async () => {
  try {
    startLoading()
    // 通常時
    const authResult = await initAuth()
    if (authResult) {
      addToast(i18n.t(authResult), 'error')
      return
    }
    if (isGottenMe.value) {
      await getMyProfile()
    }
  } catch (e) {
    console.error(e)
  } finally {
    endLoading()
    loadGtm()
  }
})

const head = useLocaleHead({
  addSeoAttributes: true,
})

useHead({
  link: [...(head.value.link || [])],
  htmlAttrs: {
    lang: i18n.locale.value,
  },
})
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

/** layout */
.tutorial-layout {
  overflow: hidden;

  > .the-header {
    height: v.$header-height-pc;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: v.$zindex-header;

    @include m.sp() {
      height: v.$header-height-sp;
    }
  }

  > .container {
    margin-top: v.$header-height-pc;
    width: 100%;

    @include m.tb {
      width: 100%;
    }

    @include m.sp {
      margin-left: 0;
      margin-top: v.$header-height-sp;
    }
  }

  > .container > .page {
    > .inner {
      position: relative;
      z-index: 1;
      @include m.landscape() {
        min-height: v.$content-height-landscape;
      }
    }
  }
}
</style>
